@media (max-aspect-ratio: 1/1) {

    #main-container {
      display: flex;
      flex-direction: column-reverse;
    }
  
  
    #cowebsite {
      left: 0;
      top: 0;
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column-reverse;
  
      &.loading {
        transform: translateY(-90%);
      }
      &.hidden {
        transform: translateY(-100%);
      }
  
      main {
        height: 100%;
      }
  
  
      aside {
        height: 30px;
        cursor: ns-resize;
        flex-direction: column;
  
        img {
          cursor: ns-resize;
        }
      }
  
      .top-right-btn {
        &#cowebsite-close {
          right: 0;
        }
        &#cowebsite-fullscreen {
          left: 0;
        }
      }
    }
  }
  